<template>
  <!-- Container fluid  -->
  <div class="container-fluid">
      <!-- Start Page Content -->
      <div class="row">
          <div class="col-12">
              <div class="card">
                  <div id="report" class="card-body">
                      <div class="container-fluid">
                        <div style="width: 80px;">
                          <img v-bind:src="$store.state.logo" style="max-width:100%; max-height:100%; display:block;"/>
                        </div>
                        <div class="headerreport">
                          <div class="row">
                            {{ companydata.cmp_nmbre }}
                          </div>
                          <div class="row">
                            {{ fecha }}
                          </div>
                        </div>
                      </div>
                      <br>
                      <div class="card-title">
                        <h1 class="titlereport">Estadística de Documentos creados por Usuarios</h1>
                        <h5 class="subtitle">{{ rango }}</h5>
                      </div>
                      <div id="wrapping" class="table-responsive m-t-40 bodyreport">
                          <table id="reporteEstadisticaRadUsuario" class="table table-sm display nowrap table-striped table-bordered" cellspacing="0" width="100%">
                              <thead>
                                  <tr>
                                      <th class="">Dependencia</th>
                                      <th class="">Usuario</th>
                                      <th class="">Clase documento</th>
                                      <th>Cantidad</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr v-for="(item, index) in items" class="rowstyle">
                                    <td>{{ item.dep_nmbre }}</td>
                                    <td>{{ item.usu_cdgo }}</td>
                                    <td>{{ item.tcr_nmbre }}</td>
                                    <td>{{ item.num }}</td>
                                  </tr>
                              </tbody>
                              <tfoot>
                                  <tr style="font-weight:bold">
                                      <td>TOTAL</td>
                                      <td></td>
                                      <td></td>
                                      <td>{{ suma }}</td>
                                  </tr>
                              </tfoot>
                          </table>
                      </div>
                      <br>
                      <div v-if="showProgress" style="text-align:center">
                        <v-progress-circular
                          :size="50"
                          :width="5"
                          color="blue"
                          indeterminate
                        ></v-progress-circular>
                      </div>
                  </div>
              </div>
              <br>
              <div class="row justify-content-around" align="center">
                <button type="button" class="btn btn-primary" v-on:click="printReport('report')">Imprimir</button>
                <button type="button" id="customXLSButton" class="btn btn-primary" v-on:click="exportReport">Exportar</button>
              </div>
          </div>
      </div>
      <!-- End PAge Content -->
  </div>
  <!-- End Container fluid  -->
</template>

<script>
//import { VProgressCircular } from 'vuetify/lib';
import moment from 'moment';
import print from 'print-js';
import XLSX from 'xlsx';

export default {
  components: { 
    //VProgressCircular
  },
  data(){
    return{
      message: '',
      items: [],
      dependencias: [],
      usuarios: [],
      tpocors: [],
      debug: null,
      fecha: null,
      rango: '',
      dcm_fcharadini: '',
      dcm_fcharadfin: '',
      showProgress: false,
      suma: 0
    }
  },
  computed:{
    companydata() {
      return this.$store.state.companydata
    }
  },
  created: function(){
    this.fecha = moment(new Date()).format('DD/MMM/YYYY hh:mm A');
    //this.fetchTpocor();
    this.fetchDependencia();
    this.fetchUsuario();
    this.fetchTpocor();
    this.debug = this.$route.params;
    this.dcm_fcharadini = this.$route.params.query.dcm_fcharadini;
    this.dcm_fcharadfin = this.$route.params.query.dcm_fcharadfin;

    this.rango = "Desde: " + moment(this.dcm_fcharadini).format('DD/MMM/YYYY') + " --- Hasta: " + moment(this.dcm_fcharadfin).format('DD/MMM/YYYY');

    this.searchItems(this.$route.params.query);
  },
  mounted: function() {
    /*
    $('#myTable').DataTable({
    dom: 'Bfrtip',
    buttons: [
        'copy',
        'pdf',
        'excel'
    ]
    });
    */
  },
  methods: {
    fetchDependencia()
    {
      let uri = '/deps/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.dependencias = response.data;

        for (var i = 0; i < this.items.length; i++){
          for (var j = 0; j < this.dependencias.length; j++){
            if (this.items[i].dep_id == this.dependencias[j]._id){
              this.items[i].dep_nmbre = this.dependencias[j].dep_nmbre;
              j = this.dependencias.length;
            }
          }
        }
        /*
        let m = response.data.index;
        this.files[m]['uploaded'] = '0';
        this.$set(this.files, m, this.files[m]);
        */
      });
    },
    fetchUsuario()
    {
      let uri = '/usuarios/' + String(this.$store.state.company);
      this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
      this.axios.get(uri).then((response) => {
        this.usuarios = response.data;

        for (var i = 0; i < this.items.length; i++){
          for (var j = 0; j < this.usuarios.length; j++){
            if (this.items[i].usu_id == this.usuarios[j]._id){
              this.items[i].usu_cdgo = this.usuarios[j].usu_cdgo;
              j = this.usuarios.length;
            }
          }
        }

      });
    },
    fetchTpocor()
    {
      let uri = '/tpocors/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.tpocors = response.data;

        for (var i = 0; i < this.items.length; i++){
          for (var j = 0; j < this.tpocors.length; j++){
            if (this.items[i].tcr_id == this.tpocors[j]._id){
              this.items[i].tcr_nmbre = this.tpocors[j].tcr_nmbre;
              j = this.tpocors.length;
            }
          }
        }

      });
    },
    searchItems(p){
      if (p != null){
        this.debug = p;
        this.showProgress = true;

        p.cmp_id = this.$store.state.company;
        //p.usu_id = String(this.$store.state.user);
        this.message = 'Buscando documentos';
        let uri = '/docs/report/radusuario';
        //this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        this.axios.post(uri, p)
        .then((response) => {
          var list = response.data;

          this.suma = 0;
          for (var i = 0; i < list.length; i++){
            /*
            for (var j = 0; j < this.tpocors.length; j++){
              if (list[i].tcr_id == this.tpocors[j]._id){
                list[i].tcr_nmbre = this.tpocors[j].tcr_nmbre;
                j = this.tpocors.length;
              }
            }
            */

            for (var j = 0; j < this.dependencias.length; j++){
              if (list[i].dep_id == this.dependencias[j]._id){
                list[i].dep_nmbre = this.dependencias[j].dep_nmbre;
                j = this.dependencias.length;
              }
            }

            for (var j = 0; j < this.usuarios.length; j++){
              if (list[i].usu_id == this.usuarios[j]._id){
                list[i].usu_cdgo = this.usuarios[j].usu_cdgo;
                j = this.usuarios.length;
              }
            }

            for (var j = 0; j < this.tpocors.length; j++){
              if (list[i].tcr_id == this.tpocors[j]._id){
                list[i].tcr_nmbre = this.tpocors[j].tcr_nmbre;
                j = this.tpocors.length;
              }
            }
            this.suma += list[i].num;

          }

          /*
            let sortBy = [{ prop:'dep_nmbre', direction: 1 },
                { prop:'usu_cdgo', direction: 1 },
                { prop:'tcr_nmbre', direction: 1 }                
            ];

            list.sort(function(a,b){
            let i = 0, result = 0;
            while(i < sortBy.length && result === 0) {
                result = sortBy[i].direction*(a[ sortBy[i].prop ].toString() < b[ sortBy[i].prop ].toString() ? -1 : (a[ sortBy[i].prop ].toString() > b[ sortBy[i].prop ].toString() ? 1 : 0));
                i++;
            }
            return result;
            });
          */

          this.items = list;

          this.showProgress = false;

        })
        .catch(err => {
          console.log(err);
          this.message = '¡Error al buscar los documentos' + err;
        });
      }
    },
    printReport(object){
      var report = window.document.getElementById('report');
      var p = window.open('', 'Imprimir');
      p.document.write(
                "<!DOCTYPE html>"+
                "<html>"+
                "<head>"+
                "<link rel='stylesheet' href='../../bootstrap/css/bootstrap.min.css'></link>"+
                "<link rel='stylesheet' href='../../css/report.css'></link>"+
                "</head>"+
                "<body>"+
                    report.innerHTML+
                "</body>"+
                "</html>");
      p.document.close();

      setTimeout(function(){ //giving it 200 milliseconds time to load
              p.focus();
              p.print();
              p.close();
      }, 1000);
    },
    exportReport(){
      var tableId = 'reporteEstadisticaRadUsuario';

      var report = [];
      var suma = 0;
      for (var i = 0; i < this.items.length; i++){
        let item = {};
        item.dep_nmbre = this.items[i].dep_nmbre;
        item.usu_cdgo = this.items[i].usu_cdgo;
        item.tcr_nmbre = this.items[i].tcr_nmbre;
        item.num = this.items[i].num;
        suma += this.items[i].num;

        report.push(item);
      }

      let item = {};
      item.dep_nmbre = 'TOTAL';
      item.usu_nmbre = '';
      item.tcr_nmbre = '';
      item.num = suma;
      report.push(item);

      /* generate a new workbook with the first rows */
      var ws = XLSX.utils.aoa_to_sheet([
        [this.companydata.cmp_nmbre],
        [this.fecha],
        [],
      	["Estadística de Correspondencia Radicada por Usuarios"],
        [this.rango],
        [],
        ["Dependencia", "Usuario", "Clase documento", "Cantidad"]
      ]);

      /* add row objects to sheet starting from cell A5 */
      var itemsWS = XLSX.utils.sheet_add_json(ws, report, { header: [], skipHeader: true, origin: "A8" });

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, itemsWS, tableId); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, tableId + '.xlsx'); // name of the file is 'book.xlsx'

    }

  } // END METHODS
}
</script>

<style>
  @import '../../../public/css/report.css';
</style>
